<template>
  <div class="help-box">
    <!-- <Search></Search> -->
    <!-- 帮助默认 -->
    <div class="content">
      <h2 class="zhonga">《快洋淘浏览器用户服务协议》</h2>
      <div class="textnoe">
        <div>
          欢迎您使用快洋淘浏览器产品及服务（以下简称“快洋淘”）。本协议由江苏星芒网络科技有限公司（以下称为“快洋淘服务商”或“我们”）与快洋淘的用户（以下称为“您”）就使用快洋淘之相关事宜所订立。
        </div>
        为使用快洋淘的功能和服务，您应当阅读并遵守《快洋淘用户服务协议》(以下简称“本协议”)，以及《快洋淘信息授权协议》。在您接受本协议之前，请您仔细阅读本协议的的全部内容（特别是下划线标注及以加粗字体显示的内容）。如您不同意本协议的任意内容，或者无法准确理解该条款的，请直接联系快洋淘服务商在线客服，并不要进行操作。您按照注册页面提示填写信息、阅读并同意本协议且完成快洋淘账号注册，或您以其他快洋淘允许的方式实际使用本服务，即表示您同意接受本协议的全部约定内容以及本协议有关的已经发布或者将来可能发布的各项规则、政策、页面展示、操作流程、公告或通知（以下合称“规则”，前述规则为本协议不可分割的组成部分，与本协议具有同等法律效力），且我们有权随时修订本协议和规则的内容并立即生效。您可在快洋淘平台上查看最新版本的协议和规则内容。若您在用户协议变更后仍继续使用快洋淘，即视为您同意此类修订。
      </div>
      <div class="textTle">
        <div class="tlei">1、服务内容</div>
        <div>
          1.1 快洋淘是由快洋淘服务商及其合作方通过包括但不限于快洋淘
          PC端应用软件及其他方式向您提供服务（以下简称“本服务”）。
        </div>
      </div>
      <div class="textTle">
        <div class="tlei">2、注册及账号管理</div>
        <div>
          2.1
          您确认，在您完成注册程序或以其他快洋淘允许的方式实际使用本服务时，您应当是具备完全民事权利能力和与您所从事的民事行为相适应的行为能力的自然人、法人或其他组织，否则您及您的监护人应承担因此导致的一切后果，且快洋淘有权注销您的账户。如您代表一家企业、组织机构或其他法律主体进行注册或以其他快洋淘允许的方式实际使用本服务的，您承诺和保证，您已获得充分授权并有权代表该公司、组织机构或法律主体注册使用快洋淘，并受本协议约束。
        </div>
        <div>
          2.2
          您可以使用您提供或确认的有效手机号、电子邮箱或快洋淘允许的其他方式进行账户注册，您承诺并保证注册时提交的信息真实、合法、准确及完整。
        </div>
        <div>
          2.3
          您了解并同意，您的快洋淘账户注册完成后，仅限您本人使用。除根据法律法规、生效的司法裁判，或经快洋淘服务商同意的情况下，您的账户不得以任何方式转让、外借或被提供予他人使用，否则快洋淘服务商有权追究您的违约责任，由此产生的一切损失均由您自行承担。
        </div>
        <div>
          2.4
          您的快洋淘账户为您自行设置并由您保管，我们只有在涉及您的账户安全时，才会要求您提供相关信息，若您对此有任何疑义，请主动联系我们的官方客服后再提供。请您务必保管好您的账户信息，并对利用该账户信息所进行的一切活动负全部责任。
        </div>
        <div>
          2.5
          您承诺，在您的账户遭到未获授权的使用或其他任何安全问题时，您将立即通知我们。您同意并确认，除法律另有规定，我们不对上述情形造成的任何直接或间接的损失及后果承担任何责任。
        </div>
      </div>
      <div class="textTle">
        <div class="tlei">3、服务费用</div>
        <div>
          3.1
          您理解并同意，您使用快洋淘所提供的部分服务为有偿服务，快洋淘有权向您收取或代为收取相应的服务费用。
        </div>
        <div>
          3.2
          您理解并同意，前款所述相关收费项目、收费标准、收费方式等规则可能会不时发生修改和变更，具体收费规则及优惠政策请以快洋淘平台或相关服务页面不时公布的信息为准。若您不同意该修改或变更，则应停止使用该服务。
        </div>
      </div>
      <div class="textTle">
        <div class="tlei">4、声明与承诺</div>
        <div>
          4.1
          您承诺在注册及使用快洋淘过程中，应及时提供快洋淘所需的一切相关资料和信息，且您对提供的资料及信息的真实性、准确性、合法性、完整性及有效性承担全部责任；否则，您须承担由此给我们造成的全部损失。
        </div>
        <div>
          4.2
          您承诺在使用快洋淘时，须遵守管辖地的法律法规、行政规章、监管机构及合作银行要求等的规定，不得利用快洋淘从事任何违法违规行为。如您违反前述承诺，我们有权暂停或注销您的账户，由此造成的责任和损失由您自行承担；此外，您还须向我们赔偿由此遭受的全部损失。
        </div>
      </div>
      <div class="textTle">
        <div class="tlei">5、第三方应用及服务</div>
        <div>
          5.1
          快洋淘允许第三方应用接入快洋淘应用商城以便您开通和使用第三方应用。您了解并同意，快洋淘仅作为平台提供者，快洋淘仅为您操作便利而提供该功能模块或服务和/或使用入口，相关应用由第三方独立提供，快洋淘不对您使用该应用产生的任何后果承担任何责任。
        </div>
        <div>
          5.2
          您了解并同意，除法律另有明确约定外，如快洋淘及第三方应用发生任何调整、中止或终止而对第三方服务产生影响的，快洋淘不承担责任。
        </div>
        <div>
          5.3
          您了解并同意，您在使用快洋淘中的第三方应用及服务时，除遵守本协议外，还应当遵守第三方用户协议。您在选择使用第三方服务前应充分了解第三方服务的产品功能、服务协议及隐私政策。
        </div>
      </div>
      <div class="textTle">
        <div class="tlei">6、信息保护</div>
        <div>
          6.1
          为向您提供更好的服务，在您使用快洋淘过程中，您同意并授权快洋淘自动保留您的Cookie信息，并可在下述情况下使用您的个人信息：
        </div>
        <div>（1）在紧急情况下，为维护用户及公众的权益。</div>
        <div>
          （2）为维护平台及快洋淘服务商的知识产权及其他任何合法权利或权益。
        </div>
        <div>（3）为获取第三方数据而将您的信息与第三方数据匹配。</div>
        <div>（4）将您的数据用于统计，以便提升或完善本服务。</div>
        <div>
          6.2
          您可根据自己的偏好管理或删除Cookie。您可以在快洋淘的设置中选择拒绝接受Cookie或限制某些Cookie或清除Cookie。拒绝Cookie时，您仍可以使用快洋淘服务，但可能导致某些功能的使用受限。
        </div>
        <div>
          6.3
          我们将运用各种合理的物理、电子和管理方面的安全措施和程序保护您的信息，以免遭受未经授权的访问、使用、披露或毁损、丢失。
        </div>
        <div>
          6.4
          我们不会将您的信息未经您的授权转移或披露给任何非关联的第三方，除非根据相关法律法规、生效的法院裁判或有效的政府机关的规定和要求对第三方披露您的信息且无需事先向您发出通知。
        </div>
      </div>
      <div class="textTle">
        <div class="tlei">7、知识产权</div>
        <div>
          7.1
          我们在本服务中提供的内容（包括但不限于网页、文字、图片、音频、视频、图表等）的知识产权归快洋淘所有，用户在使用本服务中所产生的内容的知识产权归用户或相关权利人所有。
        </div>
        <div>
          7.2
          除另有特别声明外，我们提供本服务时所依托软件的著作权、专利权及其他知识产权均归快洋淘所有。
        </div>
        <div>
          7.3 我们在本服务中所使用的商业标识，其著作权或商标权归快洋淘所有。
        </div>
        <div>
          7.4
          上述及其他任何本服务包含的内容的知识产权均受到法律保护，未经我们或相关权利人书面许可，任何人不得以任何形式进行使用或创造相关衍生作品。
        </div>
        <div>
          7.5
          对于您提供、发布及在使用快洋淘服务中形成的除个人信息外的文字、图片、视频、音频等非个人信息，在法律规定的保护期限内您免费授予快洋淘服务商及其关联公司获得全球排他的许可使用权利及再授权给其他第三方使用并可以自身名义对第三方侵权行为取证及提起诉讼的权利。您同意快洋淘服务商及其关联公司存储、使用、复制、修订、编辑、发布、展示、翻译、分发您的非个人信息或制作其派生作品，并以已知或日后开发的形式、媒体或技术将上述信息纳入其它作品内。
        </div>
      </div>
      <div class="textTle">
        <div class="tlei">8、违约责任</div>
        <div>
          8.1
          如您违反本协议约定的，我们有权不经通知随时采取包括但不限于以下违约处理措施：
        </div>
        <div>
          （1）【信息处理】您通过快洋淘发布的信息构成违约的，我们可根据相应规则立即对相应信息进行删除、屏蔽处理并暂停服务；
        </div>
        <div>
          （2）【行为限制】您在快洋淘上实施的行为，或虽未在快洋淘上实施但对快洋淘服务及其用户产生影响的行为，我们可依据相应规则对您执行中止向您提供部分或全部服务、划扣违约金等处理措施。如您的行为构成根本违约的，我们可查封您的账户，终止向您提供服务；
        </div>
        <div>
          （3）【处理结果公示】我们可将对您上述违约行为处理措施信息以及其他经国家行政或司法机关生效法律文书确认的违法信息在快洋淘上予以公示；
        </div>
        <div>（4）追究法律责任等措施。</div>
        <div>
          8.2
          您同意，由于您使用本服务、违反本协议、或您侵害他人权利而致使任何第三人向快洋淘提出任何索赔或请求，或使快洋淘服务商及/或其关联公司遭受任何损失（包括自身的直接经济损失、商誉损失及对外支付的赔偿金、和解款、律师费、诉讼费等间接经济损失），您将足额进行赔偿。
        </div>
        <div>
          8.3 特别约定：
          如您向快洋淘服务商及/或其关联公司的雇员或顾问等提供实物、现金、现金等价物、劳务、旅游等价值明显超出正常商务洽谈范畴的利益，则可视为您存在商业贿赂行为。发生上述情形的，我们可立即终止与您的所有合作并向您收取违约金及/或赔偿金，该等金额以我们因您的贿赂行为而遭受的经济损失和商誉损失作为计算依据。
        </div>
        <div>
          8.4
          如您因严重违约导致我们终止本协议时，出于维护服务秩序及保护消费者权益的目的，快洋淘服务商及/或其关联公司可对与您在其他协议项下的合作采取中止甚或终止协议的措施，并以本协议第十二条约定的方式通知您。
        </div>
        <div>
          8.5
          我们依据本条约定获得处理违法违规内容的权利，该权利不构成我们的义务或承诺，我们不能保证及时发现违法行为或进行相应处理。
        </div>
      </div>
      <div class="textTle">
        <div class="tlei">9、不可抗力及其他免责事由</div>
        <div>
          9.1
          您理解并同意，在使用本服务的过程中，可能会遇到系统的中断、故障、障碍、延误、不定期的系统升级或不可抗力等风险因素，使本服务发生中断或终止。不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成重大影响的客观事件，包括不限于已经对接的跨境电商平台的政策规则、技术接口发生的调整或变更，自然灾害如洪水、地震、瘟疫流行和风暴等以及社会事件如战争、动乱、政府行为、法律或政策调整等。我们不因前述原因对您承担任何责任。
        </div>
        <div>
          9.2 在法律允许的范围内，我们对以下情形导致的服务中断或受阻不承担责任：
        </div>
        <div>(1) 受到计算机病毒、木马或其他恶意程序、黑客攻击的破坏；</div>
        <div>(2) 电脑软件、系统、硬件和通信线路出现故障；</div>
        <div>(3) 您操作不当；</div>
        <div>(4) 您通过非快洋淘授权或许可的方式使用本服务；</div>
        <div>(5) 其他我们无法控制或合理预见的情形。</div>
        <div>
          9.3
          您理解并同意，在使用本服务的过程中，可能会遇到网络信息或其他用户行为带来的风险，我们不对任何信息的真实性、适用性、合法性承担责任，也不对因侵权行为给您造成的损害负责。这些风险包括但不限于：
        </div>
        <div>
          (1) 来自他人匿名或冒名的含有威胁、诽谤、令人反感或非法内容的信息；
        </div>
        <div>
          (2)
          因使用本协议项下的服务，遭受他人误导、欺骗或其他导致或可能导致的任何心理、生理上的伤害以及经济上的损失；
        </div>
        <div>(3) 其他因网络信息或用户行为引起的风险。</div>
        <div>
          9.4
          您理解并同意，您使用本服务的结果所直接或间接产生的任何风险均由您自行承担，快洋淘对此不做任何形式的保证，包括但不限于不保证：本服务结果将满足您的具体需求；本服务将不中断、绝对安全或无任何差错；本服务结果绝对准确或可靠；本服务的任何错误都将被纠正。
        </div>
      </div>
      <div class="textTle">
        <div class="tlei">10、服务的变更、中断、终止</div>
        <div>
          10.1
          我们可能会对服务内容进行变更，也可能会中断、中止或终止快洋淘部分或全部服务。
        </div>
        <div>
          10.2
          您理解并同意，我们有权自主决定经营策略。在发生合并、分立、收购、资产转让时，我们可向第三方转让本服务下相关资产；也可在单方通知您后，将本协议下部分或全部服务转交由第三方运营或履行。具体受让主体以快洋淘通知的为准。
        </div>
        <div>
          10.3
          如发生下列任何一种情形，我们有权不经通知而中断或终止向您提供的服务：
        </div>
        <div>
          (1）您向快洋淘提供的信息不真实、不准确、不合法或与注册时信息不一致又未能提供合理证明；
        </div>
        <div>(2）您违反相关法律法规或本协议的约定；</div>
        <div>
          (3）您盗用他人账户、发布违禁信息、骗取他人财物、扰乱市场秩序、采取不正当手段谋利等行为，我们依据服务规则对您的账户予以查封的；
        </div>
        <div>
          (4）对本软件产品进行反向工程（Reverse
          Engineer）、反向编译（Decompile）或反向汇编（Disassemble），或改动编译程序文件内部的任何资源；
        </div>
        <div>
          (5）未经我们同意，进行商业性的销售、复制、分发，包括但不限于软件销售、预装、捆绑等；
        </div>
        <div>(6）按照法律规定或主管部门的要求；</div>
        <div>(7）出于安全的原因或其他必要的情形；</div>

        <div>
          10.4
          快洋淘有权对相关服务进行收费。若您未按时足额付费，快洋淘有权中断、中止或终止提供相应服务。
        </div>
        <div>
          10.5
          您有责任自行备份存储在本服务中的数据。如果您的服务被终止，快洋淘可以从服务器上永久地删除您的数据,但法律法规另有规定的除外。服务终止后，快洋淘没有义务向您返还数据。
        </div>
        <div>10.6 本协议终止后，我们仍享有下列权利：</div>
        <div>(1）继续保存您留存于快洋淘中的各类信息；</div>
        <div>(2）对于您过往的违约行为，仍可依据本协议向您追究违约责任。</div>
      </div>
      <div class="textTle">
        <div class="tlei">11、管辖与法律适用</div>
        <div>
          11.1
          本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区（不含香港、澳门及台湾地区）法律。
        </div>
        <div>11.2 本协议签订地为中华人民共和国浙江省杭州市滨江区。</div>
        <div>
          11.3
          若您和我们之间发生任何纠纷或争议，首先应友好协商解决；协商不成的，您同意将纠纷或争议提交至杭州仲裁委员会，并依据该会届时有效的仲裁规则进行最终仲裁。
        </div>
        <div>
          11.4
          本协议所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本协议涵义解释的依据。
        </div>
        <div>
          11.5
          本协议无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "login_agre",
  data() {
    return {};
  },
  head() {
    return {
      title: "用户协议-快洋淘浏览器",
    };
  },
  methods: {},
};
</script>

<style scoped lang="less">
.content {
  width: 1200px;
  // height: 975px;
  padding-bottom: 120px;
  background: #fff;
  margin: auto;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 40px;
}

.help-box {
  background-color: #fff;
  // height: 4000px;
  padding-top: 40px;
}

.zhonga {
  width: 312px;
  height: 33px;
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #2c354b;
  line-height: 33px;
  margin: auto;
}

.textnoe {
  width: 1120px;
  height: 192px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #2c354b;
  line-height: 24px;
  margin-top: 31px;
}

.textTle {
  width: 1120px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #2c354b;
  line-height: 24px;

  div {
    margin-top: 12px;
  }

  .tlei {
    width: 1120px;
    height: 24px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #2c354b;
    line-height: 24px;
    margin-bottom: 12px;
    margin-top: 28px;
  }
}
</style>
